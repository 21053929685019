import axios from 'axios'
import Util from "../../Util.js"

var ADMIN_BACKEND_URL = process.env.VUE_APP_ADMIN_BACKEND_URL
var MOBILE_BACKEND_URL = process.env.VUE_APP_MOBILE_BACKEND_URL
var BUYER_BACKEND_URL = process.env.VUE_APP_BUYER_BACKEND_URL

export default class RestResource {
    async loadActivities() {
        const url = `${ADMIN_BACKEND_URL}/activity/list`
        return await axios.post(url, {}, Util.setupHeaders());
    }

    async findActivityById(id) {
        const url = `${ADMIN_BACKEND_URL}/activity/findActivityById/${id}`
        return await axios.post(url, {}, Util.setupHeaders());
    }

    async createActivity(activity) {
        const url = `${ADMIN_BACKEND_URL}/activity/createActivity`
        return await axios.post(url, activity, Util.setupHeaders());
    }

    async updateActivity(activity) {
        const url = `${ADMIN_BACKEND_URL}/activity/updateActivity/${activity._id}`
        return await axios.post(url, activity, Util.setupHeaders());
    }

    async deleteActivity(activityId) {
        const url = `${ADMIN_BACKEND_URL}/activity/deleteActivity`
        return await axios.post(url, { activityId: activityId }, Util.setupHeaders());
    }

    async fetchRewards(data) {
        const url = `${ADMIN_BACKEND_URL}/reward/fetch`
        return await axios.post(url, data, Util.setupHeaders());
    }

    async fetchUserIds() {
        const url = `${ADMIN_BACKEND_URL}/user/fetchUserIds`
        return await axios.post(url, {}, Util.setupHeaders())
    }

    async assignProject(project) {
        const url = `${MOBILE_BACKEND_URL}/admin/user/addProject`
        return await axios.post(url, project, Util.setupHeaders())
    }

    async fetchProjects() {
        const url = `${ADMIN_BACKEND_URL}/project/fetchProjects`
        return await axios.post(url, {}, Util.setupHeaders())
    }

    async fetchActivities(params) {
        const url = `${ADMIN_BACKEND_URL}/project/fetchActivities`
        return await axios.post(url, params, Util.setupHeaders())
    }

    async removeUserActivities(project) {
        const url = `${ADMIN_BACKEND_URL}/project/removeUserActivities`
        return await axios.post(url, project, Util.setupHeaders())
    }

    async fetchUserActivities(data) {
        const url = `${ADMIN_BACKEND_URL}/project/fetchUserActivities`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async createProjectApp(project) {
        const url = `${ADMIN_BACKEND_URL}/project/createProject`
        return await axios.post(url, project, Util.setupHeaders())
    }

    async fetchProject(params) {
        const url = `${ADMIN_BACKEND_URL}/project/fetchProject`
        return await axios.post(url, params, Util.setupHeaders());
    }
    
    async updateProject(project) {
        const url = `${ADMIN_BACKEND_URL}/project/updateProject/${project.projectId}`
        return await axios.post(url, project, Util.setupHeaders());
    }

    async fetchUsers(params) {
        const url = `${ADMIN_BACKEND_URL}/user/fetchUsers`
        return await axios.post(url, params, Util.setupHeaders())
    }

    async findUser(params) {
        const url = `${ADMIN_BACKEND_URL}/user/findUser`
        return await axios.post(url, params, Util.setupHeaders());
    }

    async findUserLocationByUserId(userId) {
        const url = `${ADMIN_BACKEND_URL}/user/findUserLocationByUserId/${userId}`
        return await axios.post(url, {}, Util.setupHeaders());
    }

    async fetchUMUser(payload) {
        const url = `${ADMIN_BACKEND_URL}/common/user/fetchUser`
        return await axios.post(url, payload, Util.setupHeaders())
    }

    async pushToDeviceByUserIds(data) {
        const url = `${ADMIN_BACKEND_URL}/user/pushToDeviceByUserIds`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async filterUsers(searchCriteria) {
        const url = `${ADMIN_BACKEND_URL}/user/filterUsers`
        return await axios.post(url, searchCriteria, Util.setupHeaders())
    }

    async fetchActiveActivitiesUserIds(data) {
        const url = `${ADMIN_BACKEND_URL}/user/fetchActiveActivityUserIds`
        return await axios.post(url, data , Util.setupHeaders())
    }

    async getMobileUsers(data){
        const url = `${ADMIN_BACKEND_URL}/user/getMobileUsers`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async getCommunityUsers(data){
        const url = `${ADMIN_BACKEND_URL}/community/getUsers`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async findUserByText(searchCriteria){
        const url = `${ADMIN_BACKEND_URL}/user/findUserByText`
        return await axios.post(url, searchCriteria, Util.setupHeaders())
    }

    async findCommunityUserByText(searchCriteria){
        const url = `${ADMIN_BACKEND_URL}/community/findCommunityUserByText`
        return await axios.post(url, searchCriteria, Util.setupHeaders())
    }

    async deactivateUser(params){
        const url = `${ADMIN_BACKEND_URL}/user/deactivateUser`
        return await axios.post(url, params, Util.setupHeaders())
    }

    async fetchOrganizations() {
        const url = `${ADMIN_BACKEND_URL}/common/organization/fetchOrganizations`
        return await axios.post(url, {}, Util.setupHeaders())
    }

    async updateUserActivity(userActivity) {
        const url = `${ADMIN_BACKEND_URL}/userActivity/updateUserActivity`
        return await axios.post(url, userActivity, Util.setupHeaders())
    }

    async changeUADataStatus(rejectRequest) {
        const url = `${ADMIN_BACKEND_URL}/userActivity/changeSubmissionStatus`
        return await axios.post(url, rejectRequest, Util.setupHeaders());
    }

    async fetchUserActivityCustom(data){
        const url = `${ADMIN_BACKEND_URL}/userActivity/fetchUserActivityCustom`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async fetchUserActivityDataCustom(data){
        const url = `${ADMIN_BACKEND_URL}/userActivity/fetchUserActivityDataCustom`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async updateUserActivityData(data){
        const url = `${ADMIN_BACKEND_URL}/userActivity/updateUserActivityData`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async updateEveryUserActivityData(data) {
        const url = `${ADMIN_BACKEND_URL}/userActivity/updateEveryUserActivityData`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async exportAppUsages(data) {
        const url = `${ADMIN_BACKEND_URL}/export/appUsages`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async exportUserActivity(data) {
        const url = `${ADMIN_BACKEND_URL}/export/userActivity`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async exportRewardCashoutBank(data) {
        const url = `${ADMIN_BACKEND_URL}/export/reward/cashout/bank`
        return await axios.post(url, data, Util.setupHeaders());
    }
    
    async createProjectCode(referralCode) {
        const url = `${ADMIN_BACKEND_URL}/projectCode/create`
        return await axios.post(url, referralCode, Util.setupHeaders());
    }

    async fetchProjectCodes(data) {
        const url = `${ADMIN_BACKEND_URL}/projectCode/fetch`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async updateProjectCode(data) {
        const url = `${ADMIN_BACKEND_URL}/projectCode/update`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async getDistinctQuestions() {
        const url = `${ADMIN_BACKEND_URL}/data/getDistinctQuestions`
        return await axios.post(url, {}, Util.setupHeaders())
    }

    async getAppList() {
        const url = `${ADMIN_BACKEND_URL}/data/getAppList`
        return await axios.post(url, {}, Util.setupHeaders())
    }

    async getCountryList() {
        const url = `${ADMIN_BACKEND_URL}/data/countryList`
        return await axios.post(url, {}, Util.setupHeaders())
    }

    async getDeviceBrand() {
        const url = `${ADMIN_BACKEND_URL}/data/getDeviceBrand`
        return await axios.post(url, {}, Util.setupHeaders())
    }

    async getDeviceOs() {
        const url = `${ADMIN_BACKEND_URL}/data/getDeviceOs`
        return await axios.post(url, {}, Util.setupHeaders())
    }

    async getImageData(data) {
        const url = `${ADMIN_BACKEND_URL}/data/getImageData`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async fetchCashout(data) {
        const url = `${ADMIN_BACKEND_URL}/cashout/fetchCashout`
        return await axios.post(url, data, Util.setupHeaders());
    }

    async updateCashout(data) {
        const url = `${ADMIN_BACKEND_URL}/cashout/updateCashout`
        return await axios.post(url, data, Util.setupHeaders());
    }

    async setCheckoutStatus(data) {
        const url = `${ADMIN_BACKEND_URL}/cashout/setCashoutStatus`
        return await axios.post(url, data, Util.setupHeaders());
    }

    async emailToUsers(data) {
        const url = `${ADMIN_BACKEND_URL}/user/emailToUsers`
        return await axios.post(url, data, Util.setupHeaders());
    }

    async fetchMultimedia(data) {
        const url = `${ADMIN_BACKEND_URL}/multimedia/fetch`
        return await axios.post(url, data , Util.setupHeaders())
    }

    async updateMultimedia(data) {
        const url = `${ADMIN_BACKEND_URL}/multimedia/update`
        return await axios.post(url, data , Util.setupHeaders())
    }

    async createMultimedia(data) {
        const url = `${ADMIN_BACKEND_URL}/multimedia/create`
        return await axios.post(url, data , Util.setupHeaders())
    }

    async getDataForSocialSharing() {
        const url = `${ADMIN_BACKEND_URL}/socialSharing/getSocialSharingData` // TODO: Rename
        return await axios.post(url, {}, Util.setupHeaders())
    }

    async getTotalUserCount(params) {
        const url = `${ADMIN_BACKEND_URL}/user/countTotalUsers`
        return await axios.post(url, params, Util.setupHeaders())
    }

    async getLastUserJoinedDate(params) {
        const url = `${ADMIN_BACKEND_URL}/user/getLastUserJoinedDate`
        return await axios.post(url, params, Util.setupHeaders())
    }

    async exportUsers(data) {
        const url = `${ADMIN_BACKEND_URL}/user/export`
        return await axios.post(url, data, Util.setupHeaders())
      }

    async findImageDataByUserId(data) {
        const url = `${ADMIN_BACKEND_URL}/socialSharing/getImagesByUserId` // TODO: Rename
        return await axios.post(url, data, Util.setupHeaders())
    }

    async updateSocialSharing(data) {
        const url = `${ADMIN_BACKEND_URL}/socialSharing/updateSocialSharing` // TODO: Rename
        return await axios.post(url, data, Util.setupHeaders())
    }

    async findEmailById(data) {
        const url = `${ADMIN_BACKEND_URL}/socialSharing/findEmailById` // TODO: Rename
        return await axios.post(url, data, Util.setupHeaders())
    }

    async sendEmailSocialSharing(data) {
        const url = `${ADMIN_BACKEND_URL}/socialSharing/sendEmailSocialSharing` // TODO: Rename
        return await axios.post(url, data, Util.setupHeaders())
    }

    async getUsersByProjectCode() {
        const url = `${ADMIN_BACKEND_URL}/user/getUsersByProjectCode`
        return await axios.post(url, {}, Util.setupHeaders())
    }

    async getActivityStatus(data) {
        const url = `${ADMIN_BACKEND_URL}/user/getActivityCount`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async changeMobileSurveySyncStatus(data) {
        const url = `${BUYER_BACKEND_URL}/admin/changeMobileSurveySyncStatus`
        return await axios.post(url, data)
    }

    async exportAllUsers() {
        const url = `${ADMIN_BACKEND_URL}/export/users/all`
        return await axios.post(url, {}, Util.setupHeaders())
    }

    async getUserProfiling(data) {
        const url = `${ADMIN_BACKEND_URL}/userProfiling/getUserProfiling`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async getUserProfilings(data) {
        const url = `${ADMIN_BACKEND_URL}/userProfiling/getUserProfilings`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async getUserProfilingData(data) {
        const url = `${ADMIN_BACKEND_URL}/userProfiling/getUserProfilingData`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async updateUserProfilingData(data) {
        const url = `${ADMIN_BACKEND_URL}/userProfiling/updateUserProfilingData`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async changeUPDataStatus(data) {
        const url = `${ADMIN_BACKEND_URL}/userProfiling/changeSubmissionStatus`
        return await axios.post(url, data, Util.setupHeaders());
    }

    async findOohBrandData(data) {
        const url = `${ADMIN_BACKEND_URL}/ooh/brand/find`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async listOohBrandData(data) {
        const url = `${ADMIN_BACKEND_URL}/ooh/brand/list`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async createOohBrandData(data) {
        const url = `${ADMIN_BACKEND_URL}/ooh/brand/create`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async updateOohBrandData(data) {
        const url = `${ADMIN_BACKEND_URL}/ooh/brand/update`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async deleteOohBrandData(data) {
        const url = `${ADMIN_BACKEND_URL}/ooh/brand/delete`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async findOohStoreData(data) {
        const url = `${ADMIN_BACKEND_URL}/ooh/store/find`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async listOohStoreData(data) {
        const url = `${ADMIN_BACKEND_URL}/ooh/store/list`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async createOohStoreData(data) {
        const url = `${ADMIN_BACKEND_URL}/ooh/store/create`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async updateOohStoreData(data) {
        const url = `${ADMIN_BACKEND_URL}/ooh/store/update`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async deleteOohStoreData(data) {
        const url = `${ADMIN_BACKEND_URL}/ooh/store/delete`
        return await axios.post(url, data, Util.setupHeaders())
    }

    async loadPlaceImages() {
        const url = `${ADMIN_BACKEND_URL}/ooh/loadPlaceImages`
        return await axios.post(url, {}, Util.setupHeaders()) 
    }

    async exportActiveUsers(data) {
        const url = `${ADMIN_BACKEND_URL}/user/filterActiveUsers`
        return await axios.post(url, data, Util.setupHeaders()) 
    }

    async approveUserSubmission(data) {
        const url = `${ADMIN_BACKEND_URL}/userActivity/approveUserSubmission` 
        return await axios.post(url, data, Util.setupHeaders())
    }

    async assignProjectCode(data) {
        const url = `${ADMIN_BACKEND_URL}/projectCode/assign` 
        return await axios.post(url, data, Util.setupHeaders())
    }

    async fetchCashoutPaypal() {
        const url = `${ADMIN_BACKEND_URL}/cashout/fetchCashoutPaypal`
        return await axios.post(url, {}, Util.setupHeaders());
    }

    async exportCashoutPaypal() {
        const url = `${ADMIN_BACKEND_URL}/export/paypalExport`
        return await axios.post(url, {}, Util.setupHeaders());
    }

    async getMobileConfig(data) {
        const url = `${MOBILE_BACKEND_URL}/data/getConfig`
        return await axios.post(url, data, Util.setupHeaders());
    }

    async updateMobileConfig(data) {
        const url = `${MOBILE_BACKEND_URL}/admin/config/update`
        return await axios.post(url, data, Util.setupHeaders());
    }

    async fetchPlantTree() {
        const url = `${ADMIN_BACKEND_URL}/cashout/fetchPlantTree`
        return await axios.post(url, {}, Util.setupHeaders());
    }

    async exportPlantTree() {
        const url = `${ADMIN_BACKEND_URL}/export/plantTreeExport`
        return await axios.post(url, {}, Util.setupHeaders());
    }

    async getStates() {
        const url = `${ADMIN_BACKEND_URL}/moo/getStates`
        return await axios.post(url, {}, Util.setupHeaders());
    }

    async getGender() {
        const url = `${ADMIN_BACKEND_URL}/moo/getGender`
        return await axios.post(url, {}, Util.setupHeaders());
    }

    async filterData(searchCriteria) {
        const url = `${ADMIN_BACKEND_URL}/moo/filterData`
        return await axios.post(url, searchCriteria, Util.setupHeaders());
    }

    async searchUser(searchCriteria){
        const url = `${ADMIN_BACKEND_URL}/user/searchUser`
        return await axios.post(url, searchCriteria, Util.setupHeaders())
    }

    async getData(data) {
        const url = `${ADMIN_BACKEND_URL}/IPurchase/getData`
        return await axios.post(url, data, Util.setupHeaders());
    }

    async getPurchaseData(data) {
        const url = `${ADMIN_BACKEND_URL}/IPurchase/getPurchaseData`
        return await axios.post(url, data, Util.setupHeaders());
    }

    async addProduct(data) {
        const url = `${ADMIN_BACKEND_URL}/IPurchase/addProduct`
        return await axios.post(url, data, Util.setupHeaders());
    }

    async exportActiveUsersByActivity(data) {
        const url = `${ADMIN_BACKEND_URL}/userActivity/getDataSubmissionByDate`
        return await axios.post(url, data, Util.setupHeaders());
    }

    async assignSurvery(data) {
        const url = `${ADMIN_BACKEND_URL}/user/assignSurvey`
        return await axios.post(url, data, Util.setupHeaders());
    }

    async createUserProfile(data) {
        const url = `${ADMIN_BACKEND_URL}/userProfiling/create`
        return await axios.post(url, data, Util.setupHeaders());
    }

    async updateUserProfile(data) {
        const url = `${ADMIN_BACKEND_URL}/userProfiling/update`
        return await axios.post(url, data, Util.setupHeaders());
    }
}