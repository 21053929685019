<template>
  <div>
    <v-col cols="12" center>
      <v-row>
        <div v-for="(images, index) in places" :key="index" class="pa-2">
          <v-img
            v-if="loaded"
            height="200px"
            width="200px"
            :src="`${images.images}`"
          />
        </div>
      </v-row>
    </v-col>
  </div>
</template>


<script>
import RestResource from "../../services/dataServiceMobile.js";
const service = new RestResource();
export default {
  data() {
    return {
      places: [],
      loaded: false,
    };
  },

  mounted() {
    this.loadPlaceImages();
  },

  methods: {
    loadPlaceImages() {
      this.$setLoader();
      service.loadPlaceImages().then((res) => {
        this.places = res.data.data;
        this.loaded = true;
        this.$disableLoader();
      });
    },
  },
};
</script>